<template>
  <v-container>
    <v-row class="mt-8">
      <v-col align="center">
        <p class="text-h3">
          Licenses & Bonds
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" lg="4" v-for="(license, index) in licenses" :key="index" align="center">
        <v-card light>
          <v-card-title class="justify-center">
            {{ license.name }}
          </v-card-title>
          <v-card-text>
            <v-img :src="license.src" width="330" height="300" @click="openGallery(index)" style="cursor: pointer"></v-img>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <LightBox ref="lightbox" :media="licenses" :showLightBox="false"></LightBox>
  </v-container>
</template>

<script>
import LightBox from 'vue-it-bigger'

import('vue-it-bigger/dist/vue-it-bigger.min.css')

export default {
  components: {LightBox},
  name: "LicensesView",
  metaInfo: {
    title: 'Licenses',
  },
  data() {
    return {
      licenses: [
        {
          name: "Contractors License",
          src: this.getAssetImg('/licenses/CSLB_xjfqju.webp'),
          thumb: this.getAssetImg('/licenses/CSLB_xjfqju.webp'),
        },
        {
          name: "City of Los Angeles Registration",
          src: this.getAssetImg('/licenses/Registration_vuqiqj.webp'),
          thumb: this.getAssetImg('/licenses/Registration_vuqiqj.webp'),
        },
        {
          name: "Liability Insurance",
          src: this.getAssetImg('/licenses/Insurance_amhdpc.webp'),
          thumb: this.getAssetImg('/licenses/Insurance_amhdpc.webp'),
        },
      ]
    }
  },
  methods: {
    openGallery(index) {
      this.$refs.lightbox.showImage(index)
    }
  }
};
</script>

<style scoped>
</style>
